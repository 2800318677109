    $("#home-slide").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        dots: true,
        arrows: false,
        adaptiveHeight: true
    });

    // anim_urls.forEach(function (item, index)
    // {
    //     var el = item.split('@');
    //     if(typeof lottie != 'undefined') {
    //         lottie.loadAnimation({
    //             container: document.getElementById(el[0]),
    //             renderer: 'svg',
    //             loop: true,
    //             autoplay: true,
    //             path: el[1]
    //         });
    //     }
    // });
